import React from 'react';
import Link from 'gatsby-link'
import { GatsbyImage } from "gatsby-plugin-image";
import { latoBold } from '../style-utils.js';
import ButtonLink from '../components/button-link';
import { Row, Col } from '../components/grid';
import styled from 'styled-components';
import theme from '../theme';


const BlogPostTitle = styled.div`
	margin-top: 18px;

	h3 {
		margin-bottom: 10px;

		a {
			color: ${props => theme.colors.black};
			text-decoration: none;
			line-height: 1.0rem;
			font-size: 1.05rem;
		}
	}

	span {
		display: block;
		color: ${props => props.color || theme.colors.blue};
		${latoBold}
		font-size: .625rem;
		margin-bottom: 0px;
		line-height: 1.2rem;
	}
`;


const BlogPost = ({node}) => {
	console.log("!!!" ,node.heroImage);
	return (
        <Col width={{sm: 1, md:1, lg:1/3}}>
			<Row>
				<Col width={{sm: 1, md:1/2, lg:1}}>
					{node.heroImage && (
						<Link to={node.slug}>
							<GatsbyImage image={node.heroImage.sizes} alt="" />
						</Link>
					)}
				</Col>
				<Col width={{sm: 1, md:1/2, lg:1}}>
					<BlogPostTitle>
						<span>{node.publishDate}</span>
						<h3><Link to={node.slug}>{node.title}</Link></h3>
					</BlogPostTitle>

					<div dangerouslySetInnerHTML={{__html: node.description.childMarkdownRemark.html}} />

					<ButtonLink
						to={node.slug}
						size="sm"
						color={theme.colors.darkGray}
						hoverColor={theme.colors.white}
						style={{ marginTop: '25px'}}
					>Läs mer</ButtonLink>
				</Col>
			</Row>
		</Col>
    );
}

export default BlogPost;
