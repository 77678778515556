// import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

import theme from '../theme';

import MainContainer from './main-container';
import SectionTitle from './section-title';


const StyledVideoSection = styled.section`
	position: relative;
	overflow: hidden;
	line-height: 1.6rem;
`;

const StyledContent = styled.div`
    font-size: .8125rem;
	padding: 30px 20px 30px 0;

	color: ${props => props.textColor};

	h4 {
		color: ${props => props.textColor};
	}
`;

const VideoWrapper = styled.div`

`;

const Wrapper = styled.div`
    display: flex;
	flex-direction: row;
    align-items: center;
    justify-content: flex-start;
	padding: ${props => (props.videoAlign === 'bottom') ? 0 : '40px 0'};

	${props => (props.videoAlign === 'fullHeight' && (`
		padding: 0;

		${VideoWrapper} {
			flex: 1;

			video {
				width: auto;
				margin-left: 50%;
				transform: translateX(-50%);
			}
		}
	`))}

	video {
		display: block !important;
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: ${props => props.breakPoint}) {
        display: block;
		padding: 20px 0 ${props => (props.videoAlign === 'bottom') ? 0 : '20px'};

		${StyledContent} {
			padding: 40px 0;
			width: 100% !important;

			* {
				text-align: center !important;
			}

			p {
				margin-bottom: 1.3rem;
			}

			${props => (props.videoAlign === 'fullHeight' && (`
				padding: 80px 0;
			`))}
		}

		${VideoWrapper} {
			position: relative !important;
			top: initial;
			bottom: initial;
			right: initial;
			width: 100% !important;
			min-height: 100px;
			margin-bottom: ${props => (props.videoAlign === 'bottom') ? 0 : '20px'};

			${props => (props.videoAlign === 'fullHeight' && (`
				position: absolute !important;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 1;
				opacity: .04;
				max-height: none !important;
				margin-bottom: 0;

				video {
					position: absolute !important;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					max-height: none !important;
					width: auto;
					transform: translateX(-50%);
				}
			`))}
		}
	}
`;

class VideoSection extends Component {

    render () {
		const { title, content } = this.props;
		const videoWidthPercent = this.props.videoWidthPercent || 50;
		const breakPoint = this.props.breakPoint
			? this.props.breakPoint + 'px'
			: theme.breakpoints.md;
		const videoAlign = this.props.videoAlign || 'center';
		const textColor = this.props.textColor || '#fff';
		return (
			<StyledVideoSection
				style={{
                    background: this.props.background
				}}
                className="clearfix"
			>
				<MainContainer>
					<Wrapper breakPoint={breakPoint} videoAlign={videoAlign}>
						<StyledContent style={{
							width: 100 - videoWidthPercent + '%'
						}} textColor={textColor}>

							<SectionTitle
								title={title}
								subtitle={this.props.subtitle}
								color={textColor}
							/>
							<div>
								{content}
							</div>
						</StyledContent>
						<VideoWrapper
							style={{
								width: videoWidthPercent + '%',
								...this.props.videoWrapperStyles || {}
							}}
						>
                            <video playsInline autoPlay loop muted style={{
								...this.props.videoStyles || {}
							}}>
                                <source src={this.props.video} type="video/mp4" />
                                <span>Your browser does not support the video tag.</span>
                            </video>
						</VideoWrapper>
					</Wrapper>
				</MainContainer>
			</StyledVideoSection>
		);
	}

}

export default VideoSection
