import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { latoBold } from  '../style-utils.js';


const StyledLink = styled.span`
	display: inline-block;

	a {
		${latoBold}
		color: ${props => props.color};
		border: 2px solid ${props => props.color};
		display: inline-block;
		padding: 7px 36px;
		font-size: .625rem;
		text-decoration: none;
		transition: 0.1s all linear;

		${props => props.size === 'sm' && `
			padding: 3px 26px;
		`}
	}

	&:hover a {
		background-color: ${props => props.color};
		color: ${props => props.hoverColor};
	}
`;

const ButtonLink = (props) => {
	const {color, children, size, hoverColor, ...rest} = props;
	return (
		<StyledLink
			color={color || '#000'}
			hoverColor={hoverColor || '#fff'}
			size={size || 'md'}
		>
			<Link {...rest}>{children}</Link>
		</StyledLink>
	);
}

export default ButtonLink;
